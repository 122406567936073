<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
    <div class="card">
      <div class="c-title">商品管理</div>
      <div class="flex">
        <el-button
          type="success"
          size="small"
          @click="comdialogVisible = true"
          class="m-b10"
          >添加评论</el-button
        >
        <el-button
          type="success"
          size="small"
          @click="saleShow = true"
          class="m-b10"
          >修改销量</el-button
        >
      </div>

      <div class="c-main">
        <div class="s-item s-head">
          <p v-for="(name, index) in listName" :key="index">{{ name }}</p>
        </div>
        <div class="s-item" v-for="(item, index) in goodsList" :key="index">
          <p>{{ item.title }}</p>
          <p>{{ item.amount }}</p>
          <p>{{ item.discount_amout }}</p>
          <p>{{ item.inventory || 0 }}</p>
          <p>{{ item.purchase_cap || "无限制" }}</p>
          <p>{{ saleList[index] }}</p>
          <p>
            <span v-if="item.type == 'coupon'">
              {{ item.status == 2 ? "上架" : "下架" }}
            </span>
            <span v-if="item.type == 'member' || item.type == 'talent'">
              {{ item.status == 1 ? "上架" : "下架" }}
            </span>
            <span v-if="item.type == 'consume'"> 上架 </span>
          </p>
          <p>
            <el-button
              type="primary"
              v-if="item.type != 'consume'"
              size="small"
              @click="openGoods(item)"
              >编辑</el-button
            >
            <el-button
              v-if="
                (item.type == 'coupon' && item.status != 2) ||
                (item.type == 'member' && item.status != 1) ||
                (item.type == 'talent' && item.status != 1)
              "
              type="success"
              size="small"
              @click="upGoods(item)"
              >上架</el-button
            >
            <el-button
              v-if="
                (item.type == 'coupon' && item.status == 2) ||
                (item.type == 'member' && item.status == 1) ||
                (item.type == 'talent' && item.status == 1)
              "
              type="danger"
              size="small"
              @click="offGoods(item)"
              >下架</el-button
            >
          </p>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="card">
      <div class="c-title flex al jc-b">
        活动管理
        <el-button size="small" type="primary" @click="openTime"
          >活动时间</el-button
        >
      </div>
      <div class="c-main">
        <el-form>
          <el-form-item label="活动名称"> 抖音直播活动 </el-form-item>
          <el-form-item label="开始时间">
            {{ active.start_time }}
          </el-form-item>
          <el-form-item label="结束时间">
            {{ active.end_time }}
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!--  -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="商品">
          {{ curGoods.title }}
        </el-form-item>
        <el-form-item label="原价">
          <el-input
            v-model="curGoods.amount"
            placeholder="直播活动价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动价格">
          <el-input
            v-model="curGoods.discount_amout"
            placeholder="直播活动价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="库存数量">
          <el-input
            v-model="curGoods.inventory"
            placeholder="库存数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="限购数量">
          <el-input
            v-model="curGoods.purchase_cap"
            placeholder="商品限购数量，无限制不填"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editGoods">确 定</el-button>
      </span>
    </el-dialog>
    <!--  -->
    <!--  -->
    <el-dialog title="提示" :visible.sync="timedialogVisible" width="30%">
      <el-form>
        <el-form-item label="开始时间">
          <el-date-picker
            v-model="s_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker
            v-model="e_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="timedialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateActiveTime">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加评论 -->
    <el-dialog
      title="添加评论"
      :visible.sync="comdialogVisible"
      :destroy-on-close="true"
    >
      <div>
        <el-form
          class="demo-form-inline"
          label-width="120px"
          label-position="left"
        >
          <el-form-item label="商品类型" required>
            <el-radio-group v-model="edit_commit_type">
              <el-radio label="coupon">优惠券</el-radio>
              <el-radio label="consume">消费金</el-radio>
              <el-radio label="member">会员卡</el-radio>
              <el-radio label="talent">天赋卡</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="内容" required>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="commit_content"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="图片">
            <images-uploader
              :clear="true"
              @getImgUrl="upload_imgs"
              :urls="commit_pic"
            ></images-uploader>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="comdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCommit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 销量 -->
    <el-dialog
      title="修改销量"
      :visible.sync="saleShow"
      :destroy-on-close="true"
    >
      <div>
        <el-form
          class="demo-form-inline"
          label-width="120px"
          label-position="left"
        >
          <el-form-item label="商品类型">
            <el-radio-group v-model="edit_sale_type" @change="changeSale">
              <el-radio label="COUPON_SALES_VOLUME">优惠券</el-radio>
              <el-radio label="CONSUME_SALES_VOLUME">消费金</el-radio>
              <el-radio label="MEMBER_SALES_VOLUME">会员卡</el-radio>
              <el-radio label="TALENT_SALES_VOLUME">天赋卡</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="销量">
            {{ oldsale }}
          </el-form-item>
          <el-form-item label="销量">
            <el-input placeholder="请输入内容" v-model="sale"> </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="saleShow = false">取 消</el-button>
        <el-button type="primary" @click="submitSale">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "抖音直播管理", secondNav: "直播活动管理" },
      dialogVisible: false,
      timedialogVisible: false,
      listName: [
        "商品名称",
        "原价",
        "直播价格",
        "库存",
        "限购数量",
        "销量",
        "状态",
        "操作",
      ],
      goodsList: [],
      curGoods: {},
      active: {},
      s_time: "",
      e_time: "",
      comdialogVisible: false,
      commit_content: "",
      commit_pic: "",
      edit_commit_type: "",
      saleList: [],
      saleShow: false,
      oldsale: "",
      sale: "",
      edit_sale_type: "",
    };
  },

  mounted() {
    this.getCoupon();
    this.getCard();
    this.getTalentCard()
    this.getActive();
    this.getSaleInfo();
    let goods = {
      title: "运动消费金",
      amount: 20,
      discount_amout: 20,
      inventory: 999999,
      purchase_cap: 0,
      status: 2,
      id: "0",
      type: "consume",
    };
    this.$set(this.goodsList, 2, goods);
    // this.$axios({
    //     url: "user/sysParam/insert",
    //     data: {
    //       param_key: "TALENT_SALES_VOLUME",
    //       param_value:'20'
    //     },
    //     method: "post",
    //   })
  },
  methods: {
    changeSale(v) {
      if (v == "MEMBER_SALES_VOLUME") {
        this.oldsale = this.saleList[1]
      }
      if (v == "COUPON_SALES_VOLUME") {
        this.oldsale = this.saleList[0]
      }
      if (v == "CONSUME_SALES_VOLUME") {
        this.oldsale = this.saleList[2]
      }
      if (v == "TALENT_SALES_VOLUME") {
        this.oldsale = this.saleList[3]
      }


    },
    submitSale() {
      if (this.edit_sale_type == "") {
        this.$message("请选择类型");
        return;
      }
      if (this.sale == "") {
        this.$message("请输入数量");
        return;
      }
      let data = {
        param_key :this.edit_sale_type,
        param_value :this.sale
      };
      this.$axios({
        url: "user/sysParam/update",
        data: data,
        method: "post",
      }).then((res) => {
        this.$message(res.data.message);
        if (res.data.code == 0) {
          this.getSaleInfo()
          this.edit_sale_type = '';
          this.sale = "";
          this.oldsale = "";
        }
      });
    },
    getSaleInfo() {
      this.$axios({
        url: "user/sysParam/queryListPage",
        params: {
          keys: "MEMBER_SALES_VOLUME,COUPON_SALES_VOLUME,CONSUME_SALES_VOLUME,TALENT_SALES_VOLUME",
        },
        method: "get",
      }).then((res) => {
        res.data.data.forEach((item) => {
          if (item.param_key == "MEMBER_SALES_VOLUME") {
            this.$set(this.saleList, 1, item.param_value);
          }
          if (item.param_key == "COUPON_SALES_VOLUME") {
            this.$set(this.saleList, 0, item.param_value);
          }
          if (item.param_key == "CONSUME_SALES_VOLUME") {
            this.$set(this.saleList, 2, item.param_value);
          }
          if (item.param_key == "TALENT_SALES_VOLUME") {
            this.$set(this.saleList, 3, item.param_value);
          }
        });
      });
    },
    // 类型(1课程,2需求,3场馆,4会员卡,5优惠券,6消费金)
    submitCommit() {
      if (this.edit_commit_type == "") {
        this.$message("请选择评论的商品");
        return;
      }
      if (this.commit_content == "") {
        this.$message("请输入内容");
        return;
      }
      let data = {
        parent_id: 0,
        content: this.commit_content,
        status: 1,
        photo_url: this.commit_pic,
        cost_effectiveness: 5,
        faculty: 5,
        environment: 5,
        course_quality: 5,
        attitude: 5,
      };
      if (this.edit_commit_type == "coupon") {
        data.type = 5;
      } else if (this.edit_commit_type == "consume") {
        data.type = 6;
      }else if (this.edit_commit_type == "talent") {
        data.type = 4;
        data.mastersetprice_id = 29
      } else {
        data.type = 4;
      }
      this.$axios({
        url: "user/masterComment/insert",
        data: data,
        method: "post",
      }).then((res) => {
        this.$message(res.data.message);
        if (res.data.code == 0) {
          this.comdialogVisible = false;
          this.commit_content = "";
          this.commit_pic = "";
        }
      });
    },
    upload_imgs(v) {
      this.commit_pic = v;
    },
    openGoods(row) {
      this.curGoods = Object.assign({}, row);
      this.dialogVisible = true;
    },
    openTime() {
      this.s_time = this.active.start_time;
      this.e_time = this.active.end_time;
      this.timedialogVisible = true;
    },
    // 活动时间
    updateActiveTime() {
      let url, data;
      url = "/user/businessActivityType/update";
      data = {
        id: 36,
        start_time: this.s_time,
        end_time: this.e_time,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        this.$message(res.data.message);
        if (res.data.code == 0) {
          this.getActive();
          this.timedialogVisible = false;
        }
      });
    },
    // 编辑商品
    editGoods() {
      let url, data;
      if (this.curGoods.type == "coupon") {
        url = "/user/commoditycoupon/update";
        data = {
          id: this.curGoods.id,
          cash:this.curGoods.amount,
          price: this.curGoods.discount_amout,
          inventory: this.curGoods.inventory,
          purchase_cap: this.curGoods.purchase_cap,
        };
      } else if (this.curGoods.type == "member" || this.curGoods.type == "talent") {
        url = "/user/member/card/update";
        data = {
          id: this.curGoods.id,
          price : this.curGoods.amount,
          discount_price: this.curGoods.discount_amout,
          inventory: this.curGoods.inventory,
        };
      }
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        this.$message(res.data.message);
        if (res.data.code == 0) {
          this.getListInfo( this.curGoods.type)
          this.dialogVisible = false;
        }
      });
    },
    // 上架
    upGoods(row) {
      let url, data;
      if (row.type == "coupon") {
        url = "/user/commoditycoupon/update";
        data = {
          id: row.id,
          status: 2,
        };
      } else if (row.type == "member" || row.type == "talent") {
        url = "/user/member/card/update";
        data = {
          id: row.id,
          status: 1,
        };
      }
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        this.$message(res.data.message);
        if (res.data.code == 0) {
          this.getListInfo(row.type)

        }
      });
    },
    // 下架
    offGoods(row) {
      let url, data;
      if (row.type == "coupon") {
        url = "/user/commoditycoupon/update";
        data = {
          id: row.id,
          status: 1,
        };
      } else if (row.type == "member" || row.type == "talent") {
        url = "/user/member/card/update";
        data = {
          id: row.id,
          status: 0,
        };
      }
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        this.$message(res.data.message);
        if (res.data.code == 0) {
          this.getListInfo(row.type)
        }
      });
    },
    getListInfo(type){
      switch (type) {
            case "coupon":
            this.getCoupon()
              break;
              case "member":
              this.getCard()
              break;
              case "talent":
            this.getTalentCard()
              break;
            default:
              break;
          }

    },
    // 优惠券信息
    getCoupon() {
      this.$axios({
        method: "get",
        url: "/user/commoditycoupon/findById",
        params: { id: 49 },
      }).then((val) => {
        if (val.data.code == 0) {
          let data = val.data.data;
          let goods = {
            title: data.name,
            amount: data.cash,
            discount_amout: data.price,
            inventory: data.inventory,
            purchase_cap: data.purchase_cap,
            status: data.status,
            id: "49",
            type: "coupon",
          };
          this.$set(this.goodsList, 0, goods);
        }
      });
    },
    // 会员卡
    getCard() {
      this.$axios({
        method: "get",
        url: "/user/member/card/findById",
        params: { id: 27 },
      }).then((val) => {
        if (val.data.code == 0) {
          let data = val.data.data;
          let goods = {
            title: data.name || '',
            amount: data.price,
            discount_amout: data.discount_price,
            inventory: data.inventory,
            purchase_cap: '',
            status: data.status,
            id: 27,
            type: "member",
          };
          this.$set(this.goodsList, 1, goods);
        }
      });
    },
       // 天赋卡
       getTalentCard() {
      this.$axios({
        method: "get",
        url: "/user/member/card/findById",
        params: { id: 29 },
      }).then((val) => {
        if (val.data.code == 0) {
          let data = val.data.data;
          let goods = {
            title: data.name || '',
            amount: data.price,
            discount_amout: data.discount_price,
            inventory: data.inventory,
            purchase_cap: '',
            status: data.status,
            id: 29,
            type: "talent",
          };
          this.$set(this.goodsList, 3, goods);
        }
      });
    },
    // 活动
    getActive() {
      this.$axios({
        method: "get",
        url: "/user/businessActivityType/findById",
        params: { id: 36 },
      }).then((val) => {
        if (val.data.code == 0) {
          this.active = val.data.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 1px 20px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  .c-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}
.s-item {
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  color: #434343;
  border-bottom: 1px solid #f8f8f8;
  @media screen and(max-width: 1280px) {
    font-size: 10px;
  }
  p {
    flex: 1;
    text-align: center;
  }
}
.s-head {
  background-color: #f8f8f8;
  font-size: 14px;
  font-weight: 600;
  @media screen and(max-width: 1280px) {
    font-size: 12px;
  }
}
</style>